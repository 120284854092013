import { fbEvent } from '@rivercode/facebook-conversion-api-nextjs';
import mixpanel, { Mixpanel } from 'mixpanel-browser';
import { event as gaEvent } from 'nextjs-google-analytics';
import { useEffect, useState } from 'react';
import { useActiveCampaign } from '../components/ActiveCampaign';
import { useMixpanel } from '../components/MixPanel';
import { IProduct } from '../services/api/product/types/IResponse';
import { ITransaction } from '../services/api/transactions/types/IResponse';
import { IUser } from '../services/api/user/types/IResponse';
import useAuth from './useAuth';

interface CommonToolOptions {
	enabled?: boolean;
	eventType?: string;
}

interface Options {
	facebook?: CommonToolOptions;
	google?: CommonToolOptions;
	mixpanel?: CommonToolOptions;
	activecampaign?: CommonToolOptions;
}

interface Data {
	transaction?: ITransaction;
	user?: IUser;
	eventId?: string;
	eventType?: string;
	product?: IProduct;
	custom?: {
		query?: string;
		writeMessage?: boolean;
	};
}

type RequiredKey<T, K extends keyof T> = Partial<T> & { [P in K]-?: T[P] };

function prepareFbData(data: RequiredKey<Data, 'eventType'>) {
	return {
		eventId: data.eventId || data.transaction?.id,
		eventName: data.eventType,
		enableStandardPixel: true,
		firstName: data.user?.firstname,
		lastName: data.user?.lastname,
		emails: data.user?.email ? [data.user?.email] : undefined,
		value: data.transaction?.takerAmount,
		currency: data.transaction?.currency.toUpperCase() || 'EUR',
		country:
			data.user?.metadata?._private?.individualInfo?.address?.country ||
			data.user?.metadata?._private?.addresses?.[0].country,
		zipCode:
			data.user?.metadata?._private?.individualInfo?.address?.zip ||
			data.user?.metadata?._private?.addresses?.[0].zip,
		city:
			data.user?.metadata?._private?.individualInfo?.address?.city ||
			data.user?.metadata?._private?.addresses?.[0].city,
		products: data.transaction?.assetId
			? [
					{
						sku: data.transaction.assetId,
						quantity: data.transaction.quantity || 1,
					},
			  ]
			: undefined,
	};
}

function prepareGaData(data: Data) {
	return {
		userId: data.user?.id,
		value: data.transaction?.value,
		category: data.transaction?.assetSnapshot.categoryId,
	};
}

function prepareMpData(data: Data) {
	return {
		platform: process.env.NEXT_PUBLIC_PLATFORM || 'Web',
		pageName: location.href,
		pageVariant: location.pathname,
		$name: data.user?.displayName,
		$email: data.user?.email,
		registrationDate: data.user?.createdDate,
		// registrationSource: undefined,
		logIn: !!data.user,
		platformsUsed: process.env.NEXT_PUBLIC_PLATFORM || 'Web',
		searchTerm: data.custom?.query,
		// filterBrand: undefined,
		// filterPrice: undefined,
		// filterCondition: undefined,
		// numberOfResults: undefined,
		itemId: data.transaction?.assetId || data.product?.id,
		itemName: data.transaction?.assetSnapshot.name || data.product?.name,
		category:
			data.transaction?.assetSnapshot.categoryId ||
			data.product?.categoryId,
		price: data.transaction?.unitPrice || data.product?.price,
		itemPrice: data.transaction?.unitPrice || data.product?.price,
		infoRequest: data.custom?.writeMessage,
		orderTotal: data.transaction?.takerAmount,
		paymentMethod: 'Credit Card',
		numberOfItems: data.transaction?.quantity,
		shippingCity: data.transaction?.metadata?.address?.City,
		// couponCode: undefined, // Mappatura non chiara
		shipped:
			data.transaction?.status === 'shipped' ||
			data.transaction?.status === 'completed' ||
			data.transaction?.status === 'delivered',
	};
}

function prepareAcData(data: Data) {
	return {
		email: data.user?.email,
		eventData: data.transaction?.takerAmount,
	};
}

export default function useTrack(props: {
	options?: Options & CommonToolOptions;
	data?: Data;
	triggerImmediately?: boolean;
}) {
	const mixpanel = useMixpanel();
	const activecampaign = useActiveCampaign();

	const user = useAuth();
	const [initialData, setInitialData] = useState<Data | undefined>(undefined);
	const [alreadyTracked, setAlreadyTracked] = useState<boolean>(false);

	useEffect(() => {
		if (user.isFetched) {
			setInitialData(
				user.isAuth
					? { user: user.user || undefined, ...props.data }
					: props.data,
			);
		}
	}, [user.isFetched]);

	useEffect(() => {
		if (props.options?.enabled === false) {
			return;
		}

		if (props.triggerImmediately && initialData && !alreadyTracked) {
			trackAll(initialData, props.options);
			setAlreadyTracked(true);
		}
	}, [props.triggerImmediately, initialData]);

	function trackAll(data?: Data, options?: Options) {
		try {
			trackFacebookEvent(data, options?.facebook);
			trackGoogleEvent(data, options?.google);
			trackMixpanelEvent(data, options?.mixpanel);
			trackActiveCampaignEvent(data, options?.activecampaign);
		} catch (e) {
			console.error(e);
		}
	}

	function trackFacebookEvent(data?: Data, options?: Options['facebook']) {
		const eventType =
			options?.eventType || data?.eventType || initialData?.eventType;

		if (options?.enabled === false || !eventType) {
			return;
		}

		const d = {
			...initialData,
			...data,
			eventType,
		};
		const fbData = prepareFbData(d);

		fbEvent(fbData);
	}

	function trackGoogleEvent(data?: Data, options?: Options['google']) {
		const eventType =
			options?.eventType || data?.eventType || initialData?.eventType;
		if (options?.enabled === false || !eventType) {
			return;
		}

		const gaData = prepareGaData({ ...initialData, ...data, eventType });
		gaEvent(eventType, gaData);
	}

	function trackMixpanelEvent(data?: Data, options?: Options['mixpanel']) {
		const eventType =
			options?.eventType || data?.eventType || initialData?.eventType;
		if (options?.enabled === false || !eventType) {
			return;
		}

		const mpData = prepareMpData({ ...initialData, ...data, eventType });
		console.debug(eventType, mpData);
		mixpanel?.track(eventType, mpData);
	}

	function trackActiveCampaignEvent(
		data?: Data,
		options?: Options['activecampaign'],
	) {
		const eventType =
			options?.eventType || data?.eventType || initialData?.eventType;
		if (options?.enabled === false || !eventType) {
			return;
		}

		const acData = prepareAcData({ ...initialData, ...data, eventType });
		activecampaign?.acTrack(eventType, acData);
	}

	return {
		trackAll,
		trackFacebookEvent,
		trackGoogleEvent,
		trackMixpanelEvent,
		trackActiveCampaignEvent,
	};
}
