import { EditIcon, ViewIcon } from '@chakra-ui/icons';
import {
	Box,
	chakra,
	ChakraProps,
	Flex,
	Icon,
	Image as Img,
	LinkBox,
	LinkOverlay,
	Skeleton,
	Spacer,
	Tag,
	TagLabel,
	Text,
} from '@chakra-ui/react';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai';
import { useQuery } from 'react-query';
import useAuth from '../../hooks/useAuth';
import useTrack from '../../hooks/useTrack';
import { Price } from '../../services/api/product';
import { IProduct } from '../../services/api/product/types/IResponse';
import IBaseProps from '../../types/interfaces/IBaseProps';
import { TPlacement } from '../../types/types/TAdv';
import API from '../../utils/api';
import optimizeImg from '../../utils/images';

interface IProps extends IBaseProps {
	widthImage?: string | number;
	heightImage?: ChakraProps['height'];
	productId?: string;
	product?: IProduct;
	placement?: TPlacement;
	alwaysShowSponsored?: boolean;
	onRemoveWishlist?: (productId: string) => void;
	cardOpacity?: ChakraProps['opacity'];
	tag?: React.ReactNode;
	withProductViewCount?: boolean;
}

function CardProduct(props: IProps) {
	const [inWishlist, setInWishlist] = useState<boolean>(false);
	const router = useRouter();
	const { user } = useAuth();

	const productReq = useQuery(
		['product', props.productId],
		async () => await API.Product.get(props.productId!),
		{ enabled: !!props.productId },
	);

	const product = props.product || productReq.data!;

	const productViewCount = product.platformData?.productViewCount;

	const trackService = useTrack({
		data: { eventType: 'AddToFavorites', product: product },
		options: { google: { eventType: 'Add_To_Favorites' } },
	});

	useEffect(() => {
		setInWishlist(
			!!user?.metadata?.wishlist?.find(pid => pid === product.id),
		);
	}, [user]);

	return (
		<>
			<Box pos={'relative'}>
				<Flex
					direction={'column'}
					opacity={props.cardOpacity || 1}
					w={'100%'}
				>
					<LinkBox
						h={'100%'}
						display="flex"
						alignItems={'center'}
						_hover={{ bg: 'gray.50' }}
					>
						<NextLink href={`/product/${product.id}`} passHref>
							<LinkOverlay
								w={'100%'}
								fontWeight={'bold'}
								color={'blackAlpha.700'}
							>
								<Img
									w={props.widthImage || '100%'}
									h={props.heightImage || 'auto'}
									objectFit={'cover'}
									src={
										product.metadata?.imageProduct
											? optimizeImg(
													product.metadata
														?.imageProduct[0],
													{
														height: props.heightImage
															? typeof props.heightImage ===
															  'string'
																? parseInt(
																		props.heightImage,
																  ) * 1.8
																: typeof props.heightImage ===
																  'number'
																? props.heightImage *
																  1.8
																: 350 * 1.8
															: undefined,
														width: props.widthImage
															? typeof props.widthImage ===
															  'string'
																? parseInt(
																		props.widthImage,
																  ) * 1.8
																: props.widthImage *
																  1.8
															: undefined,
														quality: 80,
														compressionLevel: 4,
														output: 'webp',
														fit: 'cover',
													},
											  )
											: ''
									}
									fallback={
										<Skeleton
											height={props.heightImage}
										></Skeleton>
									}
									loading={'lazy'}
								></Img>
							</LinkOverlay>
						</NextLink>
					</LinkBox>

					<Flex mt={2} mx={2} lineHeight={1.3}>
						<Flex id="number_wishlist" flexFlow={'column'}>
							{product.ownerId !== user?.id ? (
								<Icon
									as={
										inWishlist
											? AiFillHeart
											: AiOutlineHeart
									}
									color={'brandOne.500'}
									boxSize={6}
									onClick={async () => {
										if (user && product.id) {
											if (!inWishlist) {
												setInWishlist(true);
												await API.User.addToWishlist(
													user?.id,
													product.id,
												);
												trackService.trackAll();
											} else {
												setInWishlist(false);
												props.onRemoveWishlist &&
													props.onRemoveWishlist(
														product.id,
													);
												await API.User.removeToWishlist(
													user?.id,
													product.id,
												);
											}
										}
									}}
									cursor={'pointer'}
								></Icon>
							) : (
								<EditIcon
									color={'brandTwo.500'}
									boxSize={6}
									onClick={() =>
										router.push(
											`/product/edit/${product.id}`,
										)
									}
									cursor={'pointer'}
								/>
							)}
							{/* <Text ml={1} color={'gray.400'} fontSize={'smaller'}>
							{product.countLikes}
						</Text> */}
							<Spacer />
							<Box display={{ base: 'none', md: 'inherit' }}>
								{(product.customAttributes?.sponsoredHome ||
									product.customAttributes
										?.sponsoredCategory) &&
									((props.placement &&
										product.platformData?.adv?.find(
											ad =>
												ad.placement ===
												props.placement,
										)?.active) ||
										(props.alwaysShowSponsored &&
											product.platformData?.adv?.some(
												ad => ad.active,
											))) && (
										<Tag
											size={'sm'}
											variant={'subtle'}
											bgColor={'gray.200'}
											color={'blackAlpha.700'}
											shadow={'sm'}
											py={1}
											px={2}
											alignItems={'center'}
										>
											<Flex align={'end'}>
												<Img
													src={
														'/images/icon_logo_gray.webp'
													}
													h={'16px'}
													loading={'lazy'}
												/>
												{/* <TagLeftIcon as={InfoIcon} /> */}
												<TagLabel
													ml={1}
													mb={'1px'}
												>
													Sponsorizzato
												</TagLabel>
											</Flex>
										</Tag>
									)}
							</Box>
						</Flex>
						<Spacer />
						<Box
							color={'gray.400'}
							fontSize={'smaller'}
							textAlign={'right'}
						>
							<Text
								noOfLines={1}
								fontSize={'sm'}
								fontWeight={'bold'}
								color={'black'}
								ml={4}
								maxW={{
									base: '100px',
									sm: '120px',
									md: '130px',
									lg: '100%',
								}}
							>
								{product.name}
							</Text>
							<Text
								fontSize={'md'}
								id="price"
								fontWeight={'bold'}
								color={'black'}
								mb={1}
								maxW={'100%'}
							>
								{Price.format(product.price)}
							</Text>
							{(product.customAttributes?.size ||
								product.customAttributes?.shoeSize) && (
								<Text id="size">
									{product.customAttributes.size ||
										product.customAttributes.shoeSize}
								</Text>
							)}
							{product.customAttributes?.brand && (
								<Text id="brand" noOfLines={1}>
									{product.customAttributes?.brand}
								</Text>
							)}
						</Box>
					</Flex>
				</Flex>
				<Box display={{ md: 'none' }} mt={2}>
					{(product.customAttributes?.sponsoredHome ||
						product.customAttributes?.sponsoredCategory) &&
						((props.placement &&
							product.platformData?.adv?.find(
								ad => ad.placement === props.placement,
							)?.active) ||
							(props.alwaysShowSponsored &&
								product.platformData?.adv?.some(
									ad => ad.active,
								))) && (
							<Tag
								size={'sm'}
								variant={'subtle'}
								bgColor={'gray.200'}
								color={'blackAlpha.700'}
								shadow={'sm'}
								py={1}
								px={2}
								alignItems={'center'}
							>
								<Flex align={'end'}>
									<Img
										src={'/images/icon_logo_gray.webp'}
										h={'16px'}
										loading={'lazy'}
									/>
									{/* <TagLeftIcon as={InfoIcon} /> */}
									<TagLabel
										ml={1}
										mb={'1px'}
									>
										Sponsorizzato
									</TagLabel>
								</Flex>
							</Tag>
						)}
				</Box>
				{props.tag}
				{props.withProductViewCount && productViewCount &&
					<Flex alignItems={'center'} justifyContent={'flex-end'}>
						<Icon
						as={ViewIcon}
						fill={'url(#brand-gradient)'}
						boxSize={5}
						/>
						<Text
						fontWeight={500}
						color={'blackAlpha.600'}
						ml={1}
						fontSize={15}
						>
						{productViewCount}{' '}
							visualizzazioni
						</Text>
				</Flex>}
			</Box>
		</>
	);
}

export default chakra(CardProduct);
